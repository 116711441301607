@import '../_spacing.scss';

.color-probability .colors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  font-size: $font-size-3;
}

.color-probability {
  .colors__empty-message {
    min-height: 80px;
  }
}