@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap);
body{background-color:#FFF5E7;box-sizing:border-box;color:#5F3E0E;margin:0;font-family:'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}a{color:#895407}button{background-color:#5FC5AC;border:none;border-radius:3px;box-sizing:border-box;color:#FFFBF5;cursor:pointer;font-weight:bolder;letter-spacing:0.5px;padding:12px 18px;width:220px}@media only screen and (min-width: 480px){button{max-width:210px}}button:disabled{background-color:#668a81;cursor:not-allowed}h2{font-size:18px;margin:0 0 4px}select{box-sizing:border-box;padding:12px 18px;margin:3px 0;width:220px}@media only screen and (min-width: 480px){select{padding:8px;width:unset}}

header{display:flex;align-items:center;position:fixed;top:0;left:0;right:0;background-color:#FFE8C6;color:#f90;font-family:'Yeseva One';height:40px;margin:0 0 20px 0;padding:12px 16px;text-align:left}@media only screen and (min-width: 480px){header{padding:12px 24px}}.header__content{display:flex;align-items:center;justify-content:center;width:100%;max-width:750px}@media only screen and (min-width: 480px){.header__content{margin:0 auto}}.header__title{font-size:18px;font-family:'Yeseva One';margin:0 16px 0 0}@media only screen and (min-width: 480px){.header__title{font-size:20px;margin:0}}@media only screen and (min-width: 992px){.header__title{font-size:24px}}.header__nav{display:flex;flex-direction:row;justify-content:space-between;flex-grow:1;margin:0 0 0 16px}@media only screen and (min-width: 480px){.header__nav{font-size:12px;margin:0 0 0 32px}}.header__nav-item{display:flex;align-items:center;cursor:pointer}.header__nav-item__label{display:none}@media only screen and (min-width: 480px){.header__nav-item__label{display:inline-block;margin:0 0 0 5px}}

.color{border:1px solid black;cursor:pointer;display:inline-block;height:30px;margin:0 2px 4px 2px;width:10px}@media only screen and (min-width: 480px){.color{margin:0 1px 4px 1px;width:8px}}.color.highlighted{border:3px solid #05B8CC;margin:0 1px 4px;height:26px}@media only screen and (min-width: 480px){.color.highlighted{width:7px}}

.matchmaking__parent__text{display:inline-block;margin-right:5px;width:140px}.matchmaking__parent__name{cursor:pointer}.matchmaking__parent__name:hover{text-decoration:underline}.matchmaking__parent__sex{margin-left:5px}.matchmaking__parent__remove{cursor:pointer;margin-left:4px}.matchmaking__matched-parent__colors .color{cursor:unset}

.matchmaking__match{display:flex;flex-direction:row;align-items:baseline;margin:0 0 8px}.matchmaking__match .color{margin-bottom:0;cursor:auto;cursor:initial;display:none}@media only screen and (min-width: 480px){.matchmaking__match .color{display:inline-block}}.matchmaking__match .matchmaking__parent{display:flex;flex-direction:row;align-items:baseline;cursor:pointer;margin:0 5px 0}.matchmaking__match .matchmaking__parent span{margin:0 0 0 5px}.matchmaking__match__chance{font-weight:bold;margin:0 0 0 5px}

.matchmaking__list ol{padding:0 0 0 16px;text-align:left}.matchmaking__list ol li{margin:5px 0}.matchmaking__list ol .matchmaking__empty-statement{display:inline-block;margin:0 0 0 -16px}

.dragon{display:flex;flex-direction:column;align-items:center;padding:10px 30px}@media only screen and (min-width: 480px){.dragon{max-width:210px;padding:10px 0}}.dragon__name{box-sizing:border-box;padding:12px 18px;margin:4px 0;width:220px}@media only screen and (min-width: 480px){.dragon__name{padding:8px;width:210px}}.dragon__section{display:flex;flex-direction:column;justify-content:center;margin:4px 0}.dragon__section select{width:220px}@media only screen and (min-width: 480px){.dragon__section select{width:210px}}

.matchmaking{display:flex;flex-direction:column;justify-content:center;width:100%}.matchmaking h1{display:block;text-align:left}.matchmaking__dragons{display:flex;flex-direction:column;justify-content:space-between}@media only screen and (min-width: 480px){.matchmaking__dragons{flex-direction:row}}.matchmaking__dragons div{display:flex;flex-direction:column;align-items:center;justify-content:center}.matchmaking__parents{padding:0 0 0 16px}.matchmaking__parents li{margin:0 0 8px 0;page-break-inside:avoid-column;break-inside:avoid-column}@media only screen and (min-width: 480px){.matchmaking__parents{max-height:700px;columns:200px auto}}.matchmaking__parents .matchmaking__parent{display:flex;flex-direction:row;align-items:center;margin:0 5px 0}.matchmaking__parents .matchmaking__empty-statement{margin:0 0 0 -16px}

.color-probability .colors{display:flex;flex-direction:row;flex-wrap:wrap;align-items:center;font-size:16px}.color-probability .colors__empty-message{min-height:80px}

#breeding-calculator{width:100%}#breeding-calculator__selectors{display:flex;flex-direction:column;align-items:center}@media only screen and (min-width: 480px){#breeding-calculator__selectors{flex-direction:row;justify-content:space-between;width:100%}}#breeding-calculator__results{display:flex;flex-direction:column;justify-content:space-between;background-color:#fff;box-sizing:border-box;border-radius:5px;padding:16px;margin:24px 0 0;width:100%}@media only screen and (min-width: 480px){#breeding-calculator__results{flex-direction:row;flex-wrap:wrap}}.results__col{display:flex;flex-direction:column;justify-content:space-between;margin:8px}@media only screen and (min-width: 480px){.results__col{min-height:100%;min-width:35%}}@media only screen and (min-width: 480px){.results__col.color-probability{min-width:60%;max-width:60%}}@media only screen and (min-width: 480px){.results__col.gene-probability{min-width:35%;max-width:35%}}.results__total{flex-basis:100%;text-align:center}

#breed-probability img{width:100px}@media only screen and (min-width: 480px){#breed-probability img{width:180px}}.breed-probability__empty-message{display:inline-block;min-height:133px}@media only screen and (min-width: 480px){.breed-probability__empty-message{min-height:213px}}.breed-probability__different-parents{display:flex;flex-direction:row;justify-content:space-between}.breed-probability__different-parents .different-parents__parent{display:flex;flex-direction:column;align-items:center;cursor:pointer}.breed-probability__different-parents .different-parents__parent .different-parents__parent-image{border:5px solid transparent}.breed-probability__different-parents .different-parents__parent.selected .different-parents__parent-image{padding:3px;border:2px solid #f90}.breed-probability__different-parents .different-parents__parent.selected .different-parents__percentage{color:#f90;font-weight:bold}.breed-probability__same-parents{display:flex;flex-direction:column;align-items:center;cursor:pointer;width:100%}.breed-probability__same-parents .same-parents__images{display:flex;flex-direction:row;justify-content:space-between;border:5px solid transparent;width:100%}.breed-probability__same-parents .same-parents__images.selected{padding:3px;border:2px solid #f90}.breed-probability__same-parents .same-parents__percentage{margin:4px 0 0}.breed-probability__same-parents .same-parents__percentage.selected{color:#f90;font-weight:bold}

.gene-probability__empty-message{display:inline-block;min-height:118px}.gene-probability__different-genes{display:flex;flex-direction:row;justify-content:space-between}.gene-probability__different-genes .different-genes__gene{display:flex;flex-direction:column;align-items:center;cursor:pointer}.gene-probability__different-genes .different-genes__gene .different-genes__gene-image{border:5px solid transparent}.gene-probability__different-genes .different-genes__gene.selected .different-genes__gene-image{padding:3px;border:2px solid #f90}.gene-probability__different-genes .different-genes__gene.selected .different-genes__percentage{color:#f90;font-weight:bold}.gene-probability__same-genes{display:flex;flex-direction:column;align-items:center;cursor:pointer;width:100%}.gene-probability__same-genes .same-genes__image{border:5px solid transparent}.gene-probability__same-genes .same-genes__percentage{margin:4px 0 0}.gene-probability__same-genes.selected .same-genes__image{padding:3px;border:2px solid #f90}.gene-probability__same-genes.selected .same-genes__percentage{color:#f90;font-weight:bold}

main{display:flex;flex-direction:column;align-items:center;max-width:750px;margin:80px 2rem 24px}@media only screen and (min-width: 480px){main{padding:0 30px;margin:80px auto;max-width:750px}}#guardian__credits{margin:24px 0 0;color:#895407}#guardian__credits a{color:#895407}

