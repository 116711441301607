$light-theme-1: #FFFFFF;
$light-theme-2: #FFFBF5;
$light-theme-3: #FFF5E7;
$light-theme-4: #FFE8C6;
$light-theme-5: #FFE2B7;
$light-theme-6: #FFAC2F;
$light-theme-7: #FF9900;

$light-theme-text-1: #895407;
$light-theme-text-2: #5F3E0E;

$light-theme-accent-1: #5FC5AC;
$light-theme-accent-2: #668a81;


