@import '_breakpoints.scss';

.color {
  border: 1px solid black;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin: 0 2px 4px 2px;
  width: 10px;
  
  @include phone-landscape {
    margin: 0 1px 4px 1px;
    width: 8px;
  }
}

.color.highlighted {
  border: 3px solid #05B8CC;
  margin: 0 1px 4px;
  height: 26px;

  @include phone-landscape {
    width: 7px;
  }
}