@import '_breakpoints.scss';
@import '_spacing.scss';

.dragon {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 30px;

  @include phone-landscape {
    max-width: 210px;
    padding: 10px 0;
  }
}

.dragon__name {
  box-sizing: border-box;
  padding: 12px 18px;
  margin: $spacing-1 0;
  width: 220px;

  @include phone-landscape {
    padding: 8px;
    width: 210px;
  }
}

.dragon__section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: $spacing-1 0;

  select {
    width: 220px;

    @include phone-landscape {
      width: 210px;
    }
  }
}