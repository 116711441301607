@import '_breakpoints.scss';
@import '_spacing.scss';
@import '_themes.scss';
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

header {
  display: flex;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: $light-theme-4;
  color: $light-theme-7;
  font-family: 'Yeseva One';
  height: 40px;
  margin: 0 0 20px 0;
  padding: $spacing-3 $spacing-4;
  text-align: left;

  @include phone-landscape {
    padding: $spacing-3 $spacing-5;
  }
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 750px;

  @include phone-landscape {
    margin:  0 auto;
  }
}

.header__title {
  font-size: $font-size-4;
  font-family: 'Yeseva One';
  margin: 0 $spacing-4 0 0;

  @include phone-landscape {
    font-size: $font-size-5;
    margin: 0;
  }

  @include tablet-landscape {
    font-size: $font-size-6;
  }
}

.header__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  margin: 0 0 0 $spacing-4;

  @include phone-landscape {
    font-size: $font-size-1;
    margin: 0 0 0 $spacing-6;
  }
}

.header__nav-item {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.header__nav-item__label {
  display: none;

  @include phone-landscape {
    display: inline-block;
    margin: 0 0 0 5px;
  }
}