@import '_breakpoints.scss';
@import '_spacing.scss';
@import '_themes.scss';

main {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 750px;
  margin: 80px 2rem $spacing-5;

  @include phone-landscape {
    padding: 0 30px;
    margin: 80px auto;
    max-width: 750px;
  }
}

#guardian__credits {
  margin: $spacing-5 0 0;
  color: $light-theme-text-1;

  a {
    color: $light-theme-text-1;
  }
}